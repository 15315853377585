/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
export default {
    path:'system',
    children: [
        {
            path:'permission',
            component:()=>import('@/views/system/PermissionView'),
            meta:{
                requiresAuth:true,
                authCode:'system:permission:list',
            },
        },
        {
            path:'department',
            component:()=>import('@/views/system/DepartmentView'),
            meta:{
                requiresAuth:true,
                authCode:'system:department:list',
            },
        },
        {
            path:'message',
            component:()=>import('@/views/system/MessageView'),
            meta:{
                requiresAuth:true,
                authCode:'system:message:list',
            },
        },
        {
            path:'notification',
            component:()=>import('@/views/system/NotificationView'),
            meta:{
                requiresAuth:true,
                authCode:'system:notification:list',
            },
        },
        {
            path:'notice',
            component:()=>import('@/views/system/NoticeView'),
            meta:{
                requiresAuth:true,
                authCode:'system:notice:list',
            },
        },
        {
            path:'police',
            component:()=>import('@/views/system/PoliceView'),
            meta:{
                requiresAuth:true,
                authCode:'system:police:list',
            },
        },
        {
            path:'virus',
            component:()=>import('@/views/system/VirusView'),
            meta:{
                requiresAuth:true,
                authCode:'system:virus:list',
            },
        },
        {
            path:'database',
            component:()=>import('@/views/system/DatabaseView'),
            meta:{
                requiresAuth:true,
                authCode:'system:database:list',
            },
        },
        {
            path:'warning',
            component:()=>import('@/views/system/WarningView'),
            meta:{
                requiresAuth:true,
                authCode:'system:warning:list',
            },
        },
        {
            path:'contact',
            component:()=>import('@/views/system/ContactView'),
            meta:{
                requiresAuth:true,
                authCode:'system:contact:list',
            },
        },
        {
            path:'group',
            component:()=>import('@/views/system/GroupView'),
            meta:{
                requiresAuth:true,
                authCode:'system:group:list',
            },
        },
        {
            path:'queue',
            component:()=>import('@/views/system/QueueView'),
            meta:{
                requiresAuth:true,
                authCode:'system:queue:list',
            },
        },
        {
            path:'task',
            component:()=>import('@/views/system/TaskView'),
            meta:{
                requiresAuth:true,
                authCode:'system:task:list',
            },
        },
        {
            path:'config',
            component:()=>import('@/views/system/ConfigView'),
            meta:{
                requiresAuth:true,
                authCode:'system:config:list',
            },
        },
        {
            path:'manager',
            component:()=>import('@/views/system/ManagerView'),
            meta:{
                requiresAuth:true,
                authCode:'system:administrator:list',
            },
        },
        {
            path:'role',
            component:()=>import('@/views/system/RoleView'),
            meta:{
                requiresAuth:true,
                authCode:'system:role:list',
            },
        },
        {
            path:'menu',
            component:()=>import('@/views/system/MenuView'),
            meta:{
                requiresAuth:true,
                authCode:'system:menu:list',
            },
        },
        {
            path:'menu2',
            component:()=>import('@/views/system/Menu2View'),
            meta:{
                requiresAuth:true,
                authCode:'system:menu:list',
            },
        },
    ]
}