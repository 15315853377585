/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
export default {
    path:'monitor',
    children: [
        {
            path:'cache',
            component:()=>import('@/views/monitor/CacheView'),
            meta:{
                requiresAuth:true,
                authCode:'monitor:cache:list',
            },
        },
        {
            path:'online',
            component:()=>import('@/views/monitor/OnlineView'),
            meta:{
                requiresAuth:true,
                authCode:'monitor:online:list',
            },
        },
        {
            path:'redis',
            component:()=>import('@/views/monitor/RedisView'),
            meta:{
                requiresAuth:true,
                authCode:'monitor:redis:list',
            },
        },
        {
            path:'server',
            component:()=>import('@/views/monitor/ServerView'),
            meta:{
                requiresAuth:true,
                authCode:'monitor:server:list',
            },
        },
        {
            path:'login',
            component:()=>import('@/views/monitor/LoginView'),
            meta:{
                requiresAuth:true,
                authCode:'monitor:login:list',
            },
        },
        {
            path:'action',
            component:()=>import('@/views/monitor/ActionView'),
            meta:{
                requiresAuth:true,
                authCode:'monitor:action:list',
            },
        },
    ]
}