/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year：2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
import CryptoJS from "crypto-js";

//AES的密钥必须是128位的倍数

export const libAes = {
    encrypt:function (plaintext,keysecret,iv){
       let ciphertext = iv ? CryptoJS.AES.encrypt(plaintext,keysecret,iv,{
            mode:CryptoJS.mode.ECB,
            padding:CryptoJS.pad.Pkcs7,
        })
           :CryptoJS.AES.encrypt(plaintext,keysecret,{
               mode:CryptoJS.mode.ECB,
               padding:CryptoJS.pad.Pkcs7,
           });
       return ciphertext;
    },
    decrypt:function (ciphertext,keysecret,iv){
        let plaintext = iv ? CryptoJS.AES.decrypt(ciphertext,keysecret,iv,{
            mode:CryptoJS.mode.ECB,
            padding:CryptoJS.pad.Pkcs7,
        })
        :CryptoJS.AES.decrypt(ciphertext,keysecret,{
                mode:CryptoJS.mode.ECB,
                padding:CryptoJS.pad.Pkcs7,
            });
        return plaintext.toString(CryptoJS.enc.Utf8);
    },
    encryptUtf8:function (plaintext,keysecret,iv){
        plaintext = CryptoJS.enc.Utf8.parse(plaintext);
        keysecret = CryptoJS.enc.Utf8.parse(keysecret);
        let ciphertext = iv ? CryptoJS.AES.encrypt(plaintext,keysecret,iv,{
            mode:CryptoJS.mode.ECB,
            padding:CryptoJS.pad.Pkcs7,
        })
        :CryptoJS.AES.encrypt(plaintext,keysecret,{
                mode:CryptoJS.mode.ECB,
                padding:CryptoJS.pad.Pkcs7,
            });
        return ciphertext.toString();
    },
    decryptUtf8:function (ciphertext,keysecret,iv){
        keysecret = CryptoJS.enc.Utf8.parse(keysecret);
        let plaintext = iv ? CryptoJS.AES.decrypt(ciphertext,keysecret,iv,{
            mode:CryptoJS.mode.ECB,
            padding:CryptoJS.pad.Pkcs7,
        })
        :CryptoJS.AES.decrypt(ciphertext,keysecret,{
                mode:CryptoJS.mode.ECB,
                padding:CryptoJS.pad.Pkcs7,
            });
        return CryptoJS.enc.Utf8.string(plaintext).toString();
    },
}