<template>
  <Layout class="ca-layout">
    <Header class="ca-header">
      <Menu mode="horizontal" class="topbar" active-name="tab-home" @on-select="onTopBarSelect">
        <div class="nav-logo">
          <Image class="logo" :src="require('@/assets/logo_400X120.png')" :fit="'contain'">
            <template #error>
              <Icon type="ios-image-outline" size="24" color="#ccc" />
            </template>
          </Image>
        </div>
        <div class="nav-menu">
          <Icon v-if="isSider" type="md-menu" size="24" @click="onCollapsed" :class="rotateIconCalc"/>
          <MenuItem name="tab-home">
            <Icon type="md-home"/>首页
          </MenuItem>
          <MenuItem v-for="(item,index) in menus" :key="item.id" :name="'tab-'+index">
            <Icon :type="item.icon"/>{{item.title}}
          </MenuItem>
        </div>
        <div class="nav-other">
          <Icon v-if="isFullScreenEnabled" :type="isFullScreen?'md-contract':'md-expand'" size="24" @click="onFullscreen" class="menu-icon"/>
          <Notification class="menu-icon ca-notification" auto-count >
            <template #icon>
              <Icon type="md-notifications-outline" size="24" />
            </template>
            <NotificationTab title="通知" name="notice" :count="unreadNoticeCalc" empty-text="目前没有通知">
              <NotificationItem v-for="(item, index) in noticeList" :key="index" />
            </NotificationTab>
            <NotificationTab title="消息" name="message" :count="unreadMessageCalc" empty-text="目前没有消息">
              <NotificationItem v-for="(item, index) in messageList" :key="index" />
            </NotificationTab>
          </Notification>
          <Submenu name="tab-user">
            <template #title>
              <Image class="avatar" :src="require('@/assets/placeholder/ept-v.png')" :fit="'fill'" :title="userinfo.nickname"/><span class="ca-user-nickname">{{userinfo.nickname}}</span>
            </template>
            <MenuItem name="user-personal">
              <Icon type="md-person" />个人资料
            </MenuItem>
            <MenuItem name="user-pwd">
              <Icon type="md-finger-print" />重置密码
            </MenuItem>
<!--            <MenuItem name="user-safe">-->
<!--              <Icon type="md-settings" />安全设置-->
<!--            </MenuItem>-->
            <MenuItem name="user-help">
              <Icon type="ios-book" />操作说明
            </MenuItem>
            <MenuItem name="user-exit" class="ca-user-exit">
              <Icon type="md-power" />退出登录
            </MenuItem>
          </Submenu>
        </div>
      </Menu>
    </Header>
    <Layout class="ca-body">
      <Sider class="ca-sider" :class="isSider?'':'ca-sider-hidden'" ref="refSlider" hide-trigger collapsible :collapsed-width="54" v-model="isCollapsed">
        <el-scrollbar>
          <Menu ref="refMenu" mode="vertical" style="width: auto;" :class="siderMenuClassCalc" :active-name="siderActiveName" :open-names="siderOpenNames" @on-select="onSiderSelect">
            <template v-for="(value, index) in sider" :key="index">
              <template v-if="isCollapsed">
                <Dropdown v-if="value.children.length>0" @on-click="onMenuSelect" placement="right-start" transfer class="ivu-menu-item" >
                  <Icon :type="value.icon?value.icon:ios-paper" /><span>{{value.title}}</span>
                  <template #list>
                    <DropdownMenu>
                      <DropdownItem v-for="(sval, sidx) in value.children"  :key="sidx" :name="sval.component_path">{{sval.title}}</DropdownItem>
                    </DropdownMenu>
                  </template>
                </Dropdown>
                <Poptip v-else trigger="hover" transfer :content="value.title" placement="right">
                  <MenuItem :name="index" :to="value.component_path">
                    <Icon :type="value.icon?value.icon:ios-paper"/><span>{{value.title}}</span>
                  </MenuItem>
                </Poptip>
              </template>
              <template v-else >
                <Submenu v-if="value.children.length>0" :name="index+''">
                  <template #title>
                    <Icon :type="value.icon?value.icon:ios-paper"/><span>{{value.title}}</span>
                  </template>
                  <MenuItem  v-for="(sval, sidx) in value.children" :key="sidx" :name="(index+'-'+sidx)" :to="sval.component_path">{{sval.title}}</MenuItem>
                </Submenu>
                <MenuItem :name="index" :to="value.component_path" v-else >
                  <Icon :type="value.icon?value.icon:ios-paper" /><span>{{value.title}}</span>
                </MenuItem>
              </template>
            </template>
          </Menu>
        </el-scrollbar>
      </Sider>
      <Content class="ca-content">
        <el-scrollbar><router-view></router-view></el-scrollbar>
      </Content>
    </Layout>
  </Layout>
  <!--组件-->
  <AppUserInfoDrawer ref="refAppUserInfo"></AppUserInfoDrawer>
  <AppResetPwdDrawer ref="refAppResetPwd"></AppResetPwdDrawer>
</template>

<script>
import {
  Content,
  Dropdown,
  Header,
  Icon,
  Image,
  Layout,
  Menu,
  MenuItem, Notification,
  Poptip,
  Sider,
  Submenu,
} from "view-ui-plus";
import AppUserInfoDrawer from "@/components/AppUserInfoDrawer";
import AppResetPwdDrawer from "@/components/AppResetPwdDrawer";

export default {
  name: "MainView",
  components: {
    AppResetPwdDrawer,
    AppUserInfoDrawer,
    Notification, Poptip, Dropdown, Submenu, Image, Icon, MenuItem, Menu, Sider, Content, Header, Layout},
  data(){
    return {
      isFullScreenEnabled:false,
      isFullScreen:false,
      isSider:false,
      isCollapsed:false,
      siderActiveName:'',//'0-0',
      siderOpenNames:[],//['0'],
      menus:[],
      sider:[],
      userinfo:{},
      noticeList:[],
      messageList:[],
    }
  },
  methods:{
    /**
     * 初始化
     */
    initData(){
      this.$codeavatar.http._post(this.$codeavatar.getApiRealPath(this.$codeavatar.api.auth_info),{},(data)=>{
        this.$root.permission = data.permissions;
        this.$codeavatar.storeData.setPermission(data.permissions);
        this.userinfo = data.user;
      });
      this.$codeavatar.http._post(this.$codeavatar.getApiRealPath(this.$codeavatar.api.auth_menus),{},(data)=>{
        this.$root.menus = data.menus;
        //过滤其他权限
        this.menus = data.menus.filter((item)=>{
          return  item.component_path != '/more';
        });
      });
    },
    /**
     * 操作
     */
    doHomeView(){
      this.$router.replace('/admin/home');
    },
    doLogout(){
      this.$Modal.confirm({
        title: '提示',
        content: '确认要退出吗？',
        closable:true,
        maskClosable:true,
        okText:'确认',
        cancelText:'放弃',
        onOk: () => {
          this.$codeavatar.http._post(this.$codeavatar.getApiRealPath(this.$codeavatar.api.auth_logout),{},()=>{
            this.$codeavatar.storeData.clear();
            this.$Message.success('已安全退出！');
            this.$router.push('/');
          },(resData)=>{
            this.$Message.fail(resData.caMsg);
          },()=>{
            this.$codeavatar.storeData.clear();
          });
        },
        onCancel: () => {
          this.$Message.info('已取消操作！');
        }
      });
    },
    /**
     * 事件
     */
    onSiderSelect(name){
      this.siderActiveName = name;
    },
    onFullscreen(){
      this.isFullScreen = !this.isFullScreen;
      if(this.isFullScreen){
        this.$codeavatar.help.openFullScreen();
      }else{
        this.$codeavatar.help.closeFullScreen();
      }
    },
    onCollapsed(){
      this.$refs.refSlider.toggleCollapse();
    },
    onTopBarSelect(itemName){
      if(itemName.startsWith('tab-')){
        if(itemName != 'tab-home'){
          //侧栏菜单导航
          this.siderOpenNames = ['0'];
          this.siderActiveName = '0-0';

          let index = parseInt(itemName.replace('tab-',''));
          this.sider = this.menus[index].children;
          this.$nextTick(()=>{
            this.$refs.refMenu.updateOpened();
            this.$refs.refMenu.updateActiveName();
          });
          this.isSider = true;
          //自动导航
          let component_path = '/admin/error/404';
          if(this.sider.length>0){
            if(this.sider[0].component_path != '' && this.sider[0].component_path != '#'){
              component_path = this.sider[0].component_path;
            }else if(this.sider[0].children.length>0){
              if(this.sider[0].children[0].component_path != '' && this.sider[0].children[0].component_path != '#'){
                component_path = this.sider[0].children[0].component_path;
              }
            }
          }
          this.$router.push(component_path);
        }else{
          this.isSider = false;
          this.doHomeView();
        }
      }else{
        switch (itemName){
          case 'user-personal':
            this.$refs.refAppUserInfo.open();
            break;
          case 'user-pwd':
            this.$refs.refAppResetPwd.open();
            break;
          case 'user-safe':
            this.$refs.refAppResetPwd.open();
            break;
          case 'user-help':
            window.open('http://www.codeavatar.vip/help/')
            break;
          case 'user-exit':
          default:
            this.doLogout();
            break;
        }
      }
    },
    onMenuSelect(itemName){
      this.$router.push(itemName);
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    this.isFullScreenEnabled = this.$codeavatar.help.isFullScreenSupport();
    this.doHomeView();
  },
  computed:{
    unreadNoticeCalc () {
      let unread = 0;
      this.noticeList.forEach(item => {
        if (!item.read) unread++;
      });
      return unread;
    },
    unreadMessageCalc () {
      let unread = 0;
      this.messageList.forEach(item => {
        if (!item.read) unread++;
      });
      return unread;
    },
    rotateIconCalc () {
      return [
        'menu-icon',
        this.isCollapsed ? 'rotate-icon' : ''
      ];
    },
    siderMenuClassCalc () {
      return [
        'menu-item',
        this.isCollapsed ? 'collapsed-menu' : ''
      ]
    }
  }
}
</script>

<style lang="scss" scoped >
/**基础样式**/
.ca-layout{
  position: relative; width: 100%; height: 100%;
  background-color: #e6a23c;
  .ca-header{
    padding: 0px; background-color: #FFFFFF;
    .topbar{
      height: 64px; line-height: 64px;
      display: flex; flex-direction: row; align-items: center; justify-content: space-between;
      .nav-logo{
        width: 200px; text-align: center;
        .logo{
          width: 150px;
        }
      }
      .nav-menu{
         flex: 1;
      }
      .nav-other{
        .avatar{
          width: 40px; height: 40px; border-radius: 50%;
        }
      }
    }
  }
  .ca-body{
    height: calc(100vh - 60px);
    background-color: #F5F5F5;
    .ca-sider{
      background-color: #FFFFFF;
    }
    .ca-sider-hidden{
      display: none;
    }
  }
}
.ca-user-nickname{
  display: inline-block; max-width: 70px; margin:0px 5px;
  overflow:hidden; text-overflow: ellipsis; vertical-align: middle;
}
.ca-user-exit{
  color: #ed4014 !important;
}
/**菜单折叠样式**/
.menu-icon{
  transition: all .3s;
  float: left; line-height: 64px; padding: 0 10px;
}
.ivu-badge .ivu-badge-count{
  top: 0px !important;
  display: none;
}
//.ca-notification{
//  position: relative;
//}
.rotate-icon{
  transform: rotate(-90deg);
}
.menu-item{
  span{
    display: inline-block;
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width .2s ease .2s;
  }
  i{
    transform: translateX(0px);
    transition: font-size .2s ease, transform .2s ease;
    vertical-align: middle;
    font-size: 16px;
  }
}
.collapsed-menu{
  span{
    width: 0px;
    transition: width .2s ease;
  }
  i{
    transform: translateX(5px);
    transition: font-size .2s ease .2s, transform .2s ease .2s;
    vertical-align: middle;
    font-size: 22px;
    margin-left:11px;
  }
  .ivu-menu-item{
    padding:14px 0px;
  }
  .ivu-poptip .ivu-menu-item{
    padding-right: 12px;
  }
}
</style>