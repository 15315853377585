import {signMd5} from "@/codeavatar/tool/lib/sign-md5";
import config from "@/codeavatar/config/config";

/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year：2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

export const libSign = {
    makeSign:function (obj,keysecret){
        if(keysecret==undefined||keysecret==''||keysecret==null) keysecret = config.baseskey;
        this.makeMd5Sign(obj,keysecret);
    },
    makeMd5Sign: function (obj,keysecret) {
        obj.caPlatform = config.app.platform;
        obj.caDevice = config.app.device;
        obj.caVersion = config.app.version;
        obj.caRndstr = signMd5.createRndStr(50);
        obj.caRndnum = signMd5.createRndNum(50);
        obj.caTimestamp = signMd5.createTimeStamp();
        let date = new Date(); let salt = date.format('yyyy-M-d').split('-').map((item)=>{
            return parseInt(item);
        });
        keysecret = keysecret.replaceAll(salt[2],(salt[1]+salt[0]).toString(16).toLowerCase());
        obj.caSign = signMd5.makeSign(obj,keysecret);
    },
}
