<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data(){
    return {
      menus:[],
      permissions:[],
    };
  },
  created() {
    this.doCheckAuth();
  },
  methods:{
    /**
     * 操作
     */
    //本地令牌检测
    doCheckAuth(){
      //检测登录状态
      if(this.$codeavatar.storeData.isAuthToken()){
        let _this = this;
        this.$codeavatar.http._get(this.$codeavatar.getApiRealPath(this.$codeavatar.api.auth_safe_api_path),{},(data)=>{
          _this.$codeavatar.storeData.setSafeApiPath(data.safe_api_path);
          console.log('getSafeApiPath',_this.$codeavatar.storeData.getSafeApiPath());
          this.$router.replace('/admin/home');
        },()=>{
          _this.$codeavatar.storeData.clear();
          this.$router.replace('/');
        });
      }else{
        this.$router.replace('/');
      }
    },
    //菜单导航
    doReadBreadcrumb(component_path){
      let pathIndexes = this.doReadPathIndexes(this.menus,component_path);
      this.$codeavatar.log.debug('[pathIndexes]',[pathIndexes,component_path]);
      let menuPath = [],menuNode = null;
      if(pathIndexes && pathIndexes.length>0){
        for (let i=0; i<pathIndexes.length; i++){
          if(i==0){
            menuNode = this.menus[pathIndexes[0]];
          }else{
            menuNode = menuNode.children[pathIndexes[i]];
          }
          menuPath.push(menuNode.title);
        }
      }
      return menuPath;
    },
    doReadPathIndexes(list,path){
      for (let item of list){
        if(item.component_path == path){
          return item.indexes;
        }else {
          if(item.children.length > 0){
            let sval = this.doReadChildPathIndexes(item.children,path);
            if(sval != null) return sval;
          }
        }
      }
    },
    doReadChildPathIndexes(list,path){
      let gval = null;
      for (let item of list){
        if(item.component_path == path){
          gval = item.indexes; break;
        }else {
          if(item.children.length > 0){
            gval = this.doReadChildPathIndexes(item.children,path);
            if(gval != null) break;
          }
        }
      }
      return gval;
    }
  }
}
</script>

<style>
@import url('@/codeavatar/theme/default/codeavatar.css');
@import url('@/codeavatar/theme/default/print.css') print;

html{
  background:#2c3e50;
}
body{
  display: block; position: fixed;width: 100%; height: 100%;
  padding: 0px !important; margin: 0px !important;
  background: #fff;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%; height: 100%;
  padding: 0px !important; margin: 0px !important;
  color: #2c3e50; background: #efefef;
}
</style>
