/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
export default {
    path:'workbench',
    children: [
        {
            path:'data',
            component:()=>import('@/views/workbench/DataView'),
            meta:{
                requiresAuth:true,
                authCode:'workbench:data:list',
            },
        },
        {
            path:'banner',
            component:()=>import('@/views/workbench/BannerView'),
            meta:{
                requiresAuth:true,
                authCode:'workbench:banner:list',
            },
        },
        {
            path:'paper',
            component:()=>import('@/views/workbench/PaperView'),
            meta:{
                requiresAuth:true,
                authCode:'workbench:paper:list',
            },
        },
        {
            path:'news',
            component:()=>import('@/views/workbench/NewsView'),
            meta:{
                requiresAuth:true,
                authCode:'workbench:news:list',
            },
        },
        {
            path:'news/form',
            component:()=>import('@/views/workbench/NewsFormView'),
            meta:{
                requiresAuth:true,
                authCode:'workbench:news:add',
            },
        }
    ]
}