/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
export default {
    path:'enterprise',
    children: [
        {
            path:'api',
            component:()=>import('@/views/enterprise/ApiView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:api:list',
            },
        },
        {
            path:'api/form',
            component:()=>import('@/views/enterprise/components/ApiFormView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:api:add',
            },
        },
        {
            path:'api/catalog',
            component:()=>import('@/views/enterprise/components/ApiCatalog'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:api:catalog_list',
            },
        },
        {
            path:'bug',
            component:()=>import('@/views/enterprise/BugView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:bug:list',
            },
        },
        {
            path:'bug/form',
            component:()=>import('@/views/enterprise/components/BugFormView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:bug:add',
            },
        },
        {
            path:'code',
            component:()=>import('@/views/enterprise/CodeView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:code:list',
            },
        },
        {
            path:'manual',
            component:()=>import('@/views/enterprise/ManualView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:manual:list',
            },
        },
        {
            path:'manual/form',
            component:()=>import('@/views/enterprise/components/ManualFormView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:manual:add',
            },
        },
        {
            path:'manual/catalog',
            component:()=>import('@/views/enterprise/components/ManualCatalog'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:manual:catalog_list',
            },
        },
        {
            path:'process',
            component:()=>import('@/views/enterprise/ProcessView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:process:list',
            },
        },
        {
            path:'project',
            component:()=>import('@/views/enterprise/ProjectView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:project:list',
            },
        },
        {
            path:'project/form',
            component:()=>import('@/views/enterprise/components/ProjectFormView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:project:add',
            },
        },
        {
            path:'quotation',
            component:()=>import('@/views/enterprise/QuotationView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:quotation:list',
            },
        },
        {
            path:'release',
            component:()=>import('@/views/enterprise/ReleaseView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:release:list',
            },
        },
        {
            path:'safety',
            component:()=>import('@/views/enterprise/SafetyView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:safety:list',
            },
        },
        {
            path:'document',
            component:()=>import('@/views/enterprise/DocumentView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:document:list',
            },
        },
        {
            path:'document/form',
            component:()=>import('@/views/enterprise/components/DocumentFormView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:document:add',
            },
        },
        {
            path:'document/chapter',
            component:()=>import('@/views/enterprise/components/DocumentChapter'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:document:chapter_list',
            },
        },
        {
            path:'fragment',
            component:()=>import('@/views/enterprise/FragmentView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:fragment:list',
            },
        },
        {
            path:'fragment/form',
            component:()=>import('@/views/enterprise/components/FragmentFormView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:fragment:add',
            },
        },
        {
            path:'remind',
            component:()=>import('@/views/enterprise/RemindView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:remind:list',
            },
        },
        {
            path:'account',
            component:()=>import('@/views/enterprise/AccountView'),
            meta:{
                requiresAuth:true,
                authCode:'enterprise:account:list',
            },
        },
    ]
}