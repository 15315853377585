<template>
  <Row :gutter="16" class-name="pg-row">
    <Col span="6">
      <Card class="part1" :bordered="false" :dis-hover="true" title="访问量">
        <template #extra>
          <Tag color="green">日</Tag>
        </template>
        <div>
          <Numeral value="25848" format="0,0"/>
          <div class="trend">
            <span>
              日同比 <Trend flag="up">12.5%</Trend>
            </span>
            <span style="margin-left: 15px;">
              周同比 <Trend flag="down">5%</Trend>
            </span>
          </div>
          <Divider size="small"/>
          <Row justify="space-between">
            <Col>总访问量</Col>
            <Col>280 万</Col>
          </Row>
        </div>
      </Card>
    </Col>
    <Col span="6">
      <Card class="part1" :bordered="false" :dis-hover="true" title="销售额">
        <template #extra>
          <Tag color="blue">月</Tag>
        </template>
        <div>
          <Numeral value="12000" prefix="￥" format="0,0"/>
          <div class="trend">
            <Tooltip content="60% 已达标" style="width: 100%;">
              <Progress :percent="60" hide-info/>
            </Tooltip>
          </div>
          <Divider size="small"/>
          <Row justify="space-between">
            <Col>总销售</Col>
            <Col>68 万</Col>
          </Row>
        </div>
      </Card>
    </Col>
    <Col span="6">
      <Card class="part1" :bordered="false" :dis-hover="true" title="订单量">
        <template #extra>
          <Tag color="red">周</Tag>
        </template>
        <div>
          <Numeral value="25848" format="0,0">
            <template #suffix>
              <span style="font-size: 22px;margin-left: 10px; margin-bottom: 10px;">单</span>
            </template>
          </Numeral>
          <div class="trend" ref="refOrderNum" id="echarts-order-num"></div>
          <Divider size="small"/>
          <Row justify="space-between">
            <Col>转化率</Col>
            <Col>60%</Col>
          </Row>
        </div>
      </Card>
    </Col>
    <Col span="6">
      <Card class="part1" :bordered="false" :dis-hover="true" title="新增用户">
        <template #extra>
          <Tooltip content="本周新增加的用户" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline"/>
          </Tooltip>
        </template>
        <div>
          <Numeral value="128" format="0,0">
            <template #suffix>
              <span style="font-size: 22px;margin-left: 10px; margin-bottom: 10px;">位</span>
            </template>
          </Numeral>
          <div class="trend">
            <AvatarList :list="avatarList" :max="avatarMaxNum" :excess-style="{color: '#f56a00', backgroundColor: '#fde3cf'}"/>
          </div>
          <Divider size="small"/>
          <Row justify="space-between">
            <Col>总用户</Col>
            <Col>10800 人</Col>
          </Row>
        </div>
      </Card>
    </Col>
  </Row>
  <Row :gutter="16" class-name="pg-row">
    <Col span="18">
      <Card class="partFlow" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="md-pulse" size="small" style="background-color: #E6F7FF; color: #2b85e4;"/> <span>访问量</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refAccessTrend">
        </div>
      </Card>
    </Col>
    <Col span="6">
      <Row :gutter="16" >
        <Col span="24">
          <Card class="partFlow2" :bordered="false" :dis-hover="true">
            <template #title>
              <Avatar shape="circle" icon="md-pulse" size="small" style="background-color: #E6F7FF; color: #2b85e4;"/> <span>访问量</span>
            </template>
            <template #extra>
              <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
                <Radio label="week">周</Radio>
                <Radio label="month">月</Radio>
                <Radio label="year">年</Radio>
              </RadioGroup>
              <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
                <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
              </Tooltip>
            </template>
            <div class="trend" ref="refAccessTrend">
            </div>
          </Card>
        </Col>
        <Col span="24" style="margin-top: 16px;">
          <Card class="partFlow2" :bordered="false" :dis-hover="true">
            <template #title>
              <Avatar shape="circle" icon="md-pulse" size="small" style="background-color: #E6F7FF; color: #2b85e4;"/> <span>访问量</span>
            </template>
            <template #extra>
              <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
                <Radio label="week">周</Radio>
                <Radio label="month">月</Radio>
                <Radio label="year">年</Radio>
              </RadioGroup>
              <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
                <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
              </Tooltip>
            </template>
            <div class="trend" ref="refAccessTrend">
            </div>
          </Card>
        </Col>
      </Row>
    </Col>
  </Row>
  <Row :gutter="16" class-name="pg-row">
    <Col span="8">
      <Card class="part2" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="md-person-add" size="small" style="background-color: #E6F7FF; color: #2b85e4;"/> <span>新用户入驻</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refUserAddTrend">
        </div>
      </Card>
    </Col>
    <Col span="8">
      <Card class="part2" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="md-pie" size="small" style="background-color: #E6F7FF; color: #2b85e4;"/> <span>用户分布</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refUserTrend">
        </div>
      </Card>
    </Col>
    <Col span="8">
      <Card class="part2" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="md-people" size="small" style="background-color: #FFFBE6; color: #FAAD14;"/> <span>用户活跃量</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refUserOnlineTrend">
        </div>
      </Card>
    </Col>
  </Row>
  <Row :gutter="16" class-name="pg-row">
    <Col span="12">
      <Card class="part2" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="md-cart" size="small" style="background-color: #FFF0F6; color: #F52285;"/> <span>订单量</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refOrderTrend">
        </div>
      </Card>
    </Col>
    <Col span="12">
      <Card class="part2" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="md-cash" size="small" style="background-color: #E6F7FF; color: #2b85e4;"/> <span>销售额</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refSalesVolumeTrend">
        </div>
      </Card>
    </Col>
  </Row>
  <Row :gutter="16" class-name="pg-row">
    <Col span="8">
      <Card class="part2" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="ios-search" size="small" style="background-color: #F9F0FF; color: #722ED1;"/> <span>热门搜索</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refHotSearchTrend">
        </div>
      </Card>
    </Col>
    <Col span="8">
      <Card class="part2" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="md-list" size="small" style="background-color: #E6F7FF; color: #2b85e4;"/> <span>热销商品</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refTopProductTrend">
        </div>
      </Card>
    </Col>
    <Col span="8">
      <Card class="part2" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="md-podium" size="small" style="background-color: #E6F7FF; color: #2b85e4;"/> <span>商品销量</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refSalesProductTrend">
        </div>
      </Card>
    </Col>
  </Row>
  <Row :gutter="16" class-name="pg-row">
    <Col span="24">
      <Card class="part2" :bordered="false" :dis-hover="true">
        <template #title>
          <Avatar shape="circle" icon="md-analytics" size="small" style="background-color: #FFF0F6; color: #F52285;"/> <span>综合分析</span>
        </template>
        <template #extra>
          <RadioGroup v-model="accessCheckTag" size="small" type="button" @on-change="onAccessTagChange">
            <Radio label="week">周</Radio>
            <Radio label="month">月</Radio>
            <Radio label="year">年</Radio>
          </RadioGroup>
          <Tooltip content="显示本周、本月、本年的数据" placement="top-end" transfer >
            <Icon type="ios-help-circle-outline" style="margin-left: 15px;"/>
          </Tooltip>
        </template>
        <div class="trend" ref="refAnalysisTrend">
        </div>
      </Card>
    </Col>
  </Row>
  <div style="height: 15px;"></div>
</template>
<script>
import * as echarts from 'echarts';
import {
  Avatar,
  AvatarList,
  Card,
  Col,
  Divider,
  Icon,
  Numeral,
  Progress, Radio,
  RadioGroup,
  Row,
  Tag,
  Tooltip,
  Trend
} from "view-ui-plus";
export default {
  name: "HomeView",
  components: {
    Radio,
    RadioGroup, Avatar, AvatarList, Icon, Tooltip, Progress, Trend, Divider, Numeral, Tag, Card, Col, Row},
  data(){
    return {
      trendWidth:100,
      avatarMaxNum:14,
      avatarList:[
        {
          src: 'https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar',
          tip: '史蒂夫·乔布斯'
        },
        {
          src: 'https://dev-file.iviewui.com/zhj85zgAfEjChCNIKT1LQENUIOyOYCaX/avatar',
          tip: '斯蒂夫·沃兹尼亚克'
        },
        {
          src: 'https://dev-file.iviewui.com/TkH54UozsINlex15TAMI00GElsfsKSiC/avatar',
          tip: '乔纳森·伊夫'
        },
        {
          src: 'https://dev-file.iviewui.com/xrzbBR99F6tYsDJPLNrvwhllowbuL7Gw/avatar',
          tip: '蒂姆·库克'
        },
        {
          src: 'https://dev-file.iviewui.com/bgrngoUb9A6UQ2kAwBFtnSNzhrh2qj1O/avatar',
          tip: '比尔·费尔南德斯'
        },
        {
          src: 'https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar',
          tip: '史蒂夫·乔布斯'
        },
        {
          src: 'https://dev-file.iviewui.com/zhj85zgAfEjChCNIKT1LQENUIOyOYCaX/avatar',
          tip: '斯蒂夫·沃兹尼亚克'
        },
        {
          src: 'https://dev-file.iviewui.com/TkH54UozsINlex15TAMI00GElsfsKSiC/avatar',
          tip: '乔纳森·伊夫'
        },
        {
          src: 'https://dev-file.iviewui.com/xrzbBR99F6tYsDJPLNrvwhllowbuL7Gw/avatar',
          tip: '蒂姆·库克'
        },
        {
          src: 'https://dev-file.iviewui.com/bgrngoUb9A6UQ2kAwBFtnSNzhrh2qj1O/avatar',
          tip: '比尔·费尔南德斯'
        },
        {
          src: 'https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar',
          tip: '史蒂夫·乔布斯'
        },
        {
          src: 'https://dev-file.iviewui.com/zhj85zgAfEjChCNIKT1LQENUIOyOYCaX/avatar',
          tip: '斯蒂夫·沃兹尼亚克'
        },
        {
          src: 'https://dev-file.iviewui.com/TkH54UozsINlex15TAMI00GElsfsKSiC/avatar',
          tip: '乔纳森·伊夫'
        },
        {
          src: 'https://dev-file.iviewui.com/xrzbBR99F6tYsDJPLNrvwhllowbuL7Gw/avatar',
          tip: '蒂姆·库克'
        },
        {
          src: 'https://dev-file.iviewui.com/bgrngoUb9A6UQ2kAwBFtnSNzhrh2qj1O/avatar',
          tip: '比尔·费尔南德斯'
        },
        {
          src: 'https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar',
          tip: '史蒂夫·乔布斯'
        },
        {
          src: 'https://dev-file.iviewui.com/zhj85zgAfEjChCNIKT1LQENUIOyOYCaX/avatar',
          tip: '斯蒂夫·沃兹尼亚克'
        },
        {
          src: 'https://dev-file.iviewui.com/TkH54UozsINlex15TAMI00GElsfsKSiC/avatar',
          tip: '乔纳森·伊夫'
        },
        {
          src: 'https://dev-file.iviewui.com/xrzbBR99F6tYsDJPLNrvwhllowbuL7Gw/avatar',
          tip: '蒂姆·库克'
        },
        {
          src: 'https://dev-file.iviewui.com/bgrngoUb9A6UQ2kAwBFtnSNzhrh2qj1O/avatar',
          tip: '比尔·费尔南德斯'
        }
      ],
      accessCheckTag:'week', //week、month、year
    }
  },
  methods:{
    /**
     * 初始化
     */
    init(){
      this.initEvent();
      this.initEchartsOrderNum();
      this.initEchartsAccessTrend();
      this.initEchartsOnlineTrend();
      this.initEchartsAddTrend();
      this.initEchartsOrderTrend();
      this.initEchartsSalesTrend();
      this.initEchartsSearchTrend();
      this.initEchartsProductTrend();
      this.initEchartsUserTrend();
      this.initEchartsAnalysisTrend();
    },
    initEvent(){
      window.onresize = ()=>{
        this.trendWidth = this.$refs.refOrderNum.clientWidth;
      }
    },
    uninitEvent(){
      window.onresize = null;
    },
    initEchartsOrderNum(isResize){
      // 基于准备好的dom，初始化echarts实例
      // var myChart = echarts.init(document.getElementById('echarts-order-num'));
      var myChart = myChart == undefined ? echarts.init(this.$refs.refOrderNum) : myChart;
      //变化大小
      if(isResize) {myChart.resize({width:this.trendWidth,})}
      // 绘制图表
      myChart.setOption({
        grid:{
          left:0,top:0,right:0,bottom:0,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:false,
          show:false,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          max:10, min:-1,
          interval:0.5,
          scale:true,
          show:false,
        },
        series: [
          {
            data: [1,4,2,3,4,0,6,7],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          }
        }
      },{ lazyMode: true });
    },
    initEchartsAccessTrend(isResize){
      var myAccessChart = echarts.init(this.$refs.refAccessTrend);
      //变化大小
      if(isResize) {myAccessChart.resize({width:this.trendWidth,})}
      // 绘制图表
      myAccessChart.setOption({
        title:{
          text:'访问量趋势',
          textStyle:{
            fontSize:14,
          },
        },
        grid:{
          top:40,right:20,bottom:20,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:false,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          // axisLabel:{
          //   formatter:'{value}%',
          // },
        },
        series: [
          {
            data: [1,4,2,3,4,0,8],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          },
          //千分位格式化 (toLocaleString)
          valueFormatter: (value) => value.toLocaleString()+' 次',
        }
      },{ lazyMode: true });
    },
    initEchartsOnlineTrend(isResize){
      var myOnlineChart = echarts.init(this.$refs.refUserOnlineTrend);
      //变化大小
      if(isResize) {myOnlineChart.resize({width:this.trendWidth,})}
      // 绘制图表
      myOnlineChart.setOption({
        title:{
          text:'访问量趋势',
          textStyle:{
            fontSize:14,
          },
        },
        grid:{
          top:40,right:20,bottom:20,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:false,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          // axisLabel:{
          //   formatter:'{value}%',
          // },
        },
        series: [
          {
            data: [1,4,2,3,4,0,8],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          },
          //千分位格式化 (toLocaleString)
          valueFormatter: (value) => value.toLocaleString()+' 次',
        }
      },{ lazyMode: true });
    },
    initEchartsAddTrend(isResize){
      var myAddChart = echarts.init(this.$refs.refUserAddTrend);
      //变化大小
      if(isResize) {myAddChart.resize({width:this.trendWidth,})}
      // 绘制图表
      myAddChart.setOption({
        title:{
          text:'访问量趋势',
          textStyle:{
            fontSize:14,
          },
        },
        grid:{
          top:40,right:20,bottom:20,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:false,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          // axisLabel:{
          //   formatter:'{value}%',
          // },
        },
        series: [
          {
            data: [1,4,2,3,4,0,8],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          },
          //千分位格式化 (toLocaleString)
          valueFormatter: (value) => value.toLocaleString()+' 次',
        }
      },{ lazyMode: true });
    },
    initEchartsOrderTrend(isResize){
      var myOrderChart = echarts.init(this.$refs.refOrderTrend);
      //变化大小
      if(isResize) {myOrderChart.resize({width:this.trendWidth,})}
      // 绘制图表
      myOrderChart.setOption({
        title:{
          text:'访问量趋势',
          textStyle:{
            fontSize:14,
          },
        },
        grid:{
          top:40,right:20,bottom:20,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:false,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          // axisLabel:{
          //   formatter:'{value}%',
          // },
        },
        series: [
          {
            data: [1,4,2,3,4,0,8],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          },
          //千分位格式化 (toLocaleString)
          valueFormatter: (value) => value.toLocaleString()+' 次',
        }
      },{ lazyMode: true });
    },
    initEchartsSalesTrend(isResize){
      var mySalesChart = echarts.init(this.$refs.refSalesVolumeTrend);
      //变化大小
      if(isResize) {mySalesChart.resize({width:this.trendWidth,})}
      // 绘制图表
      mySalesChart.setOption({
        title:{
          text:'访问量趋势',
          textStyle:{
            fontSize:14,
          },
        },
        grid:{
          top:40,right:20,bottom:20,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:false,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          // axisLabel:{
          //   formatter:'{value}%',
          // },
        },
        series: [
          {
            data: [1,4,2,3,4,0,8],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          },
          //千分位格式化 (toLocaleString)
          valueFormatter: (value) => value.toLocaleString()+' 次',
        }
      },{ lazyMode: true });
    },
    initEchartsSearchTrend(isResize){
      var mySearchChart = echarts.init(this.$refs.refHotSearchTrend);
      //变化大小
      if(isResize) {mySearchChart.resize({width:this.trendWidth,})}
      // 绘制图表
      mySearchChart.setOption({
        title:{
          text:'访问量趋势',
          textStyle:{
            fontSize:14,
          },
        },
        grid:{
          top:40,right:20,bottom:20,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:false,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          // axisLabel:{
          //   formatter:'{value}%',
          // },
        },
        series: [
          {
            data: [1,4,2,3,4,0,8],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          },
          //千分位格式化 (toLocaleString)
          valueFormatter: (value) => value.toLocaleString()+' 次',
        }
      },{ lazyMode: true });
    },
    initEchartsProductTrend(isResize){
      var myProductChart = echarts.init(this.$refs.refSalesProductTrend);
      //变化大小
      if(isResize) {myProductChart.resize({width:this.trendWidth,})}
      // 绘制图表
      myProductChart.setOption({
        title:{
          text:'访问量趋势',
          textStyle:{
            fontSize:14,
          },
        },
        grid:{
          top:40,right:20,bottom:20,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:false,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          // axisLabel:{
          //   formatter:'{value}%',
          // },
        },
        series: [
          {
            data: [1,4,2,3,4,0,8],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          },
          //千分位格式化 (toLocaleString)
          valueFormatter: (value) => value.toLocaleString()+' 次',
        }
      },{ lazyMode: true });
    },
    initEchartsUserTrend(isResize){
      var myUserChart = echarts.init(this.$refs.refUserTrend);
      //变化大小
      if(isResize) {myUserChart.resize({width:this.trendWidth,})}
      // 绘制图表
      myUserChart.setOption({
        title:{
          text:'访问量趋势',
          textStyle:{
            fontSize:14,
          },
        },
        grid:{
          top:40,right:20,bottom:20,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:false,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          // axisLabel:{
          //   formatter:'{value}%',
          // },
        },
        series: [
          {
            data: [1,4,2,3,4,0,8],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          },
          //千分位格式化 (toLocaleString)
          valueFormatter: (value) => value.toLocaleString()+' 次',
        }
      },{ lazyMode: true });
    },
    initEchartsAnalysisTrend(isResize){
      var myAnalysisChart = echarts.init(this.$refs.refAnalysisTrend);
      //变化大小
      if(isResize) {myAnalysisChart.resize({width:this.trendWidth,})}
      // 绘制图表
      myAnalysisChart.setOption({
        title:{
          text:'访问量趋势',
          textStyle:{
            fontSize:14,
          },
        },
        grid:{
          left:20, top:40,right:20,bottom:20,
        },
        xAxis: {
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          boundaryGap:true,
        },
        yAxis: {
          type: 'value',
          boundaryGap:false,
          axisLabel:{
            // formatter:'{value}%',
            inside:true
          },
          show:true,
        },
        series: [
          {
            data: [1,4,2,3,4,0,89999],
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'rgba(178,240,209,1)',
            },
            lineStyle:{
              color:'rgba(0,204,102,1)',
            },
            itemStyle:{
              color:'rgba(0,204,102,1)',
            },
          }
        ],
        tooltip:{
          show:true,
          trigger:'axis',
          axisPointer:{
            axis:'x',
            snap:true,
          },
          //千分位格式化 (toLocaleString)
          valueFormatter: (value) => value.toLocaleString()+' 次',
        }
      },{ lazyMode: true });
    },
    /**
     * 事件
     */
    onAccessTagChange(e){
      console.log(e);
    },
  },
  mounted() {
    this.init();
  },
  watch:{
    trendWidth:{
      // eslint-disable-next-line no-unused-vars
      handler(newVal,oldVal){
        this.initEchartsOrderNum(true);
        this.avatarMaxNum = parseInt(newVal/25);
      },
      immediate: false,
      deep:true
    }
  },
  unmounted() {
    this.uninitEvent();
  },
}
</script>

<style lang="scss" scoped>
.pg-row{
  margin: 15px 8px 5px 8px !important;
  .part1{
    .ivu-numeral{
      font-size: 30px;
    }
    .trend{
      height: 42px;
      display: flex; flex-direction: row; align-items: center;
      span{
        line-height: 42px;
      }
    }
    .ivu-divider{
      margin: 8px 0px;
    }
  }
  .part2{
    .trend{
      height: 200px;
      display: flex; flex-direction: row; align-items: center;
    }
  }
  .partFlow{
    .trend{
      height: 400px;
      display: flex; flex-direction: row; align-items: center;
    }
  }
  .partFlow2{
    .trend{
      height: 158px;
      display: flex; flex-direction: row; align-items: center;
    }
  }
}
</style>
<style lang="scss" >
/*覆盖框架样式*/
.ivu-card{
  .ivu-card-head{
    padding: 10px 16px; line-height: 14px;
    p{
      font-size: 14px;
    }
  }
  .ivu-card-extra{
    top: 10px;
  }
  .ivu-card-body{
    padding: 12px;
  }
}
</style>