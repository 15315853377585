<template>
  <Drawer :model-value="isVisible" @on-close="onClose" class-name="ca-drawer" :scrollable="true" :title="layoutTitle" width="500" placement="right">
    <div class="drawer-body">
      <Form ref="refForm" :model="formData" :rules="formRule" :label-width="90" label-position="right" :label-colon="true">
        <Row :gutter="20">
          <Col span="24">
            <FormItem label="昵称" prop="nickname" >
              <Input v-model="formData.nickname" placeholder="请输入昵称" maxlength="50" show-word-limit />
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="邮箱" prop="email" >
              <Input type="email" v-model="formData.email" placeholder="请输入电子邮箱" maxlength="50" show-word-limit />
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="电话" prop="cellphone" >
              <Input type="tel" v-model="formData.cellphone" placeholder="请输入手机号码" maxlength="11" show-word-limit />
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="简介" prop="desc">
              <Input type="textarea" :rows="4" class="ca-resize" placeholder="请输入简介" maxlength="300" clearable v-model="formData.desc" show-word-limit/>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
    <div class="drawer-footer">
      <Button style="margin-right: 10px" @click="onAction('cancel')">取消</Button>
      <Button type="primary" @click="onAction('ok')" :loading="isSubmitting">保存</Button>
    </div>
  </Drawer>
</template>

<script>
import {
  Button,
  Checkbox, Col,
  Drawer, Form, Input, Row,
  Table
} from "view-ui-plus";

const FORM_VALS = {
  nickname:'',
  email:'',
  cellphone:'',
  desc:'',
};

export default {
  name: "AppUserInfoDrawer",
  components: {Row, Input, Col, Form, Button, Checkbox, Drawer, Table},
  props:{},
  data(){
    return{
      isVisible:false,
      layoutTitle:'个人资料',
      formData:{},
      formRule:{
        nickname:[
          { required: true, message: '请输入昵称', trigger: 'blur' },
        ],
        email:[
          { required: true, message: '请输入电子邮箱', trigger: 'blur', },
          { type: 'email', message: '电子邮箱格式错误', trigger: 'blur',  },
        ],
        cellphone:[
          // { type:'string', required: true, message: '请输入电话', trigger: 'blur',transform(value){return value.trim();} },
          { type:'string', required: false, pattern:/^1[0-9]{10}$/, message: '电话格式错误', trigger: 'blur'},
        ],
      },
      isSubmitting:false,
    }
  },
  methods:{
    /**
     * 【基础】
     */
    open(data){
      this.doLoadData();
      this.isVisible = true;
    },
    /**
     * 【初始化】
     */
    init(){
      this.initData();
    },
    initData(){
      this.formData = this.$codeavatar.help.copyConstObj(FORM_VALS);
    },
    /**
     * 操作
     */
    doLoadData(){
      let _this = this;
      this.isLoading = true;
      this.$codeavatar.http._get(this.$codeavatar.getApiRealPath(this.$codeavatar.api.other_personal_list),{},(data)=>{
        _this.$codeavatar.help.copyProperties(data,_this.formData);
      },null,()=>{
        this.isLoading = false;
      });
    },
    /**
     * 事件
     */
    onAction(type){
      switch (type){
        case 'cancel':
          this.onClose();
          break;
        case 'ok':
          this.$refs.refForm.validate((valid)=>{
            if(valid){
              this.$Loading.start();
              this.isSubmitting = true;
              // eslint-disable-next-line no-unused-vars
              this.$codeavatar.http._post(this.$codeavatar.getApiRealPath(this.$codeavatar.api.other_personal_update),this.formData,(data,resData)=>{
                // this.$Message.success('提交成功');
                this.$Message.success(resData.caMsg);
                this.onClose();
              },null,()=>{
                this.$Loading.finish();
                this.isSubmitting = false;
              });
            }else{
              this.$Message.warning('输入有误，请检查!');
            }
          });
          break;
      }
    },
    onClose(){
      this.isVisible = false;
    },
  },
  created() {

  },
  mounted() {
    this.init();
  },
}
</script>

<style lang="scss" scoped>

</style>