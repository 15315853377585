/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

// eslint-disable-next-line no-unused-vars
import {createRouter, createWebHistory, createWebHashHistory} from "vue-router";
//admin路径
import adminRouter from "@/codeavatar/router/admin-router";

const router = createRouter({
    history:createWebHistory(),
    routes: adminRouter
});
export default router;

