/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year：2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

//系统信息
const appInfo={
    allName:'宇宙流星系统管理中心',
    simpleName:'宇宙流星',
};

export default {
    app:appInfo,
};