<template>
  <Drawer :model-value="isVisible" @on-close="onClose" class-name="ca-drawer" :scrollable="true" :title="layoutTitle" width="500" placement="right">
    <div class="drawer-body">
      <Form ref="refForm" :model="formData" :rules="formRule" :label-width="90" label-position="right" :label-colon="true">
        <Row :gutter="20">
          <Col span="24">
            <FormItem label="帐号" prop="account" >
<!--              <Input v-model="formData.account" readonly placeholder="请输入帐号" maxlength="50" show-word-limit />-->
              {{formData.account}}
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="原始密码" prop="olpassword" >
              <Input v-model="formData.olpassword" type="password" password placeholder="请输入原始密码" maxlength="50" show-word-limit />
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="新密码" prop="password" >
              <Input v-model="formData.password" type="password" password placeholder="请输入新密码" maxlength="50" show-word-limit ></Input>
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="确认密码" prop="repassword" >
              <Input v-model="formData.repassword" type="password" password placeholder="请输入确认密码" maxlength="50" show-word-limit ></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
    <div class="drawer-footer">
      <Button style="margin-right: 10px" @click="onAction('cancel')">取消</Button>
      <Button type="primary" @click="onAction('ok')" :loading="isSubmitting">保存</Button>
    </div>
  </Drawer>
</template>

<script>
import {
  Button,
  Checkbox, Col,
  Drawer, Form, Input, Row,
  Table
} from "view-ui-plus";

const FORM_VALS = {
  account:'',
  olpassword:'',
  password:'',
  repassword:'',
};

export default {
  name: "AppResetPwdDrawer",
  components: {Row, Input, Col, Form, Button, Checkbox, Drawer, Table},
  props:{},
  data(){
    const validateRepassword = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error('请输入确认密码'));
      }else if(value != this.formData.password){
        callback(new Error('确认密码与新密码不一致'));
      } else {
        callback();
      }
    };
    return{
      isVisible:false,
      layoutTitle:'重置密码',
      formData:{},
      formRule:{
        olpassword:[
          { required: true, message: '请输入原始密码', trigger: 'blur' },
        ],
        password:[
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { type: 'string', min:5, max:30, message: '新密码必须是5至30个字符', trigger: 'change' },
        ],
        repassword:[
          { required: true, validator:validateRepassword, trigger: 'blur' },
        ],
      },
      isSubmitting:false,
    }
  },
  methods:{
    /**
     * 【基础】
     */
    open(data){
      this.doLoadData();
      this.isVisible = true;
    },
    /**
     * 【初始化】
     */
    init(){
      this.initData();
    },
    initData(){
      this.formData = this.$codeavatar.help.copyConstObj(FORM_VALS);
    },
    /**
     * 操作
     */
    doLoadData(){
      let _this = this;
      this.isLoading = true;
      this.$codeavatar.http._get(this.$codeavatar.getApiRealPath(this.$codeavatar.api.other_setting_list),{},(data)=>{
        _this.$codeavatar.help.copyProperties(data,_this.formData);
      },null,()=>{
        this.isLoading = false;
      });
    },
    /**
     * 事件
     */
    onAction(type){
      switch (type){
        case 'cancel':
          this.onClose();
          break;
        case 'ok':
          this.$refs.refForm.validate((valid)=>{
            if(valid){
              this.$Loading.start();
              this.isSubmitting = true;
              // eslint-disable-next-line no-unused-vars
              this.$codeavatar.http._post(this.$codeavatar.getApiRealPath(this.$codeavatar.api.other_setting_resetpwd),this.formData,(data,resData)=>{
                // this.$Message.success('提交成功');
                this.$Message.success(resData.caMsg);
                this.onClose();
              },null,()=>{
                this.$Loading.finish();
                this.isSubmitting = false;
              });
            }else{
              this.$Message.warning('输入有误，请检查!');
            }
          });
          break;
      }
    },
    onClose(){
      this.isVisible = false;
      this.$refs.refForm.resetFields();
    },
  },
  created() {

  },
  mounted() {
    this.init();
  },
}
</script>

<style lang="scss" scoped>

</style>