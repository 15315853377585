/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
import axios from 'axios';
import store from "@/codeavatar/data/data";
import caConfig from "@/codeavatar/config/config";
import logTool from "@/codeavatar/tool/log-tool";

/**
 * 更多配置：https://axios-http.com/zh/docs/req_config
 *
 * @type {axios.AxiosInstance}
 */
const instance = axios.create({
    // `url` 是用于请求的服务器 URL
    url: '',
    // `method` 是创建请求时使用的方法
    method: 'get', // 默认值
    // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
    // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
    baseURL: caConfig.baseurl, //http://127.0.0.1:1234
    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 它只能用于 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    // 数组中最后一个函数必须返回一个字符串， 一个Buffer实例，ArrayBuffer，FormData，或 Stream
    // 你可以修改请求头。
    // eslint-disable-next-line no-unused-vars
    transformRequest: [function (data, headers) {
        // 对发送的 data 进行任意转换处理
        logTool.debug('[axios-transformRequest]',data);
        return data;
    }],

    // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
    transformResponse: [function (data) {
        // 对接收的 data 进行任意转换处理

        return data;
    }],

    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        // 'Content-Type': 'application/x-www-form-urlencoded', //格式：a=val1&b=val2
        // 'Content-Type': 'application/json',  //{a:'val1',b:'val2'}
        // 'Content-Type': 'multipart/form-data', //form = new FormData()
    },
    // `timeout` 指定请求超时的毫秒数。
    // 如果请求时间超过 `timeout` 的值，则请求会被中断
    timeout: 60000, // 默认值是 `0` (永不超时)
    // `withCredentials` 表示跨域请求时是否需要使用凭证
    withCredentials: true, //默认（false）
    // `adapter` 允许自定义处理请求，这使测试更加容易。（开启后则原始方法不运行）
    // 返回一个 promise 并提供一个有效的响应 （参见 lib/adapters/README.md）。
    // eslint-disable-next-line no-unused-vars
    // adapter: function (config) {
    //     /* ... */
    // },
    // // `auth` HTTP Basic Auth
    // auth: {
    //     username: 'codeavatar',
    //     password: 'codeavatar2023'
    // },
    // `responseType` 表示浏览器将要响应的数据类型
    // 选项包括: 'arraybuffer', 'document', 'json', 'text', 'stream'
    // 浏览器专属：'blob'
    responseType: 'json', // 默认值
    // `responseEncoding` 表示用于解码响应的编码 (Node.js 专属)
    // 注意：忽略 `responseType` 的值为 'stream'，或者是客户端请求
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: 'utf8', // 默认值
    // `xsrfCookieName` 是 xsrf token 的值，被用作 cookie 的名称
    xsrfCookieName: 'XSRF-TOKEN', // 默认值
    // `xsrfHeaderName` 是带有 xsrf token 值的http 请求头名称
    xsrfHeaderName: 'X-XSRF-TOKEN', // 默认值
    // `onUploadProgress` 允许为上传处理进度事件
    // 浏览器专属
    // eslint-disable-next-line no-unused-vars
    onUploadProgress: function (progressEvent) {
        // 处理原生进度事件
    },
    // `onDownloadProgress` 允许为下载处理进度事件
    // 浏览器专属
    // eslint-disable-next-line no-unused-vars
    onDownloadProgress: function (progressEvent) {
        // 处理原生进度事件
    },
    // `maxContentLength` 定义了node.js中允许的HTTP响应内容的最大字节数
    maxContentLength: 2000,

    // `maxBodyLength`（仅Node）定义允许的http请求内容的最大字节数
    maxBodyLength: 2000,
});
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    //自动设置头部
    if (config.data instanceof FormData) {
        // Object.assign(config.headers, config.data.getHeaders());
        //或者
        Object.assign(config.headers, {'Content-Type':'multipart/form-data'});
    }else if(config.data instanceof Object){
        config.data = JSON.stringify(config.data);
        Object.assign(config.headers, {'Content-Type':'application/json'});
    }else if(config.data instanceof String){
        Object.assign(config.headers, {'Content-Type':'application/x-www-form-urlencoded'});
    }else{
        Object.assign(config.headers, {'Content-Type':'application/x-www-form-urlencoded'});
    }
    //设置令牌
    let Authorization = store.getAuthToken();
    config.headers.set('Authorization',Authorization);
    // logTool.debug('[axios-interceptors-request]',config.headers);
    logTool.debug('[axios-interceptors-request]',{url:config.baseURL+config.url,params:(config.data?config.data:config.params),header:config.headers});
    return config;
}, function (error) {
    // 对请求错误做些什么
    if (error.request) {
        logTool.error('[axios-interceptors-request]', error.request);
    } else if (error.response) {
        logTool.error('[axios-interceptors-request]', error.response);
    } else {
        logTool.error('[axios-interceptors-request]', error.message);
    }
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    logTool.debug('[axios-interceptors-response]', response);
    return response;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    if (error.response) {
        // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
        // 结构data、status、headers
        logTool.error('[axios-interceptors-response]', error.response);
    } else if (error.request) {
        // 请求已经成功发起，但没有收到响应
        // `error.request` 在浏览器中是 XMLHttpRequest 的实例，
        // 而在node.js中是 http.ClientRequest 的实例
        logTool.error('[axios-interceptors-response]', error.request);
    } else {
        // 发送请求时出了点问题
        logTool.error('[axios-interceptors-response]', error.message);
    }
    return Promise.reject(error);
});
export default instance;
