<template>
  <div class="ca-login">
    <div class="sysName">{{systemName}}</div>
    <Tabs model-value="account" class="tabs" :animated="false">
      <TabPane v-if="loginMode>0" label="帐户" name="account" icon="md-person" class="account">
        <Login @on-submit="handleSubmit1">
          <div class="title">用户名</div>
          <UserName name="username" value="" clearable class="item" />
          <div class="title">密码</div>
          <Password name="password" value="" clearable class="item" :enter-to-submit="true" />
<!--          <Captcha class="ca-captcha" name="captcha" :value="captchaCode" :field="['username','password']" :enter-to-submit="true" clearable :count-down="0" @on-get-captcha="handleGetCaptcha">-->
<!--            <template #text>-->
<!--              <img :src="captchaUrl">-->
<!--            </template>-->
<!--          </Captcha>-->
          <Submit class="submit" />
          <Vue3Verify ref="refVerify" captchaType="blockPuzzle" mode="pop"  @success="onChkSuccess" @error="onChkError" @ready="onChkReady" :imgSize="{ width: '330px', height: '155px' }"></Vue3Verify>
        </Login>
      </TabPane>
      <TabPane v-if="loginMode>1" label="手机号" name="phone" icon="md-phone-portrait" class="account">
        <Login @on-submit="handleSubmit2">
          <div class="title">手机号</div>
          <Mobile name="mobile" clearable />
          <div class="title">验证码</div>
          <Captcha name="captcha" clearable :field="['mobile']" @on-get-captcha="handleGetCode" />
          <Submit />
        </Login>
      </TabPane>
      <TabPane v-if="loginMode>2" label="扫码" name="scanner" icon="md-qr-scanner" class="scanner">
        <Image :fit="'cover'" alt="登录码" width="100" height="100" :src="qrcode" class="ca-img" >
          <template #placeholder>
            <div class="ca-spin">
              <Spin fix>
                <Icon type="ios-loading" size=54 class="ca-spin-loding"></Icon>
                <div>加载中...</div>
              </Spin>
            </div>
          </template>
          <template #error>
            <div class="ca-spin">
              <Icon type="ios-image-outline" size="54" color="#ccc" />
              <div>加载失败</div>
            </div>
          </template>
        </Image>
      </TabPane>
    </Tabs>
    <!--底部版权-->
    <div class="copyright">
      <a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备2023017988号</a>
    </div>
  </div>
</template>

<script>

import {Icon, Image, Login, TabPane, Tabs} from "view-ui-plus";
import Vue3Verify from "@/components/verifition/Verify";
import setting from "@/codeavatar/config/setting";

export default {
  name: "LoginView",
  components: {Vue3Verify, Icon, Image, TabPane, Tabs, Login},
  data(){
    return{
      loginMode:1,  //选项：1》帐户；2》手机号（待完善）；3》扫码（待实现）
      captchaUrl:'',
      captchaCode:'',
      qrcode:'http://www.baidu.com/s?wd=%E7%99%BE%E5%BA%A6%E7%83%AD%E6%90%9C&sa=ire_dl_gh_logo_texing&rsv_dl=igh_logo_pcs',
      captchaParams:null,
      systemName:'',
    }
  },
  methods:{
    /**
     * 事件区域
     */
    onChkSuccess(params){
      // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
      this.captchaParams = params;
      this.$Message.info({
        content: '验证通过，请点击登录！',
        duration: 3,
        background:true,
      });
    },
    onChkError(){
      this.captchaParams = null;
    },
    onChkReady(){
      // this.$refs.refVerify.show();
    },

    /**
     * 逻辑区域
     */
    //帐户
    // eslint-disable-next-line no-unused-vars
    handleSubmit1 (valid, { username, password, captcha }) {
      let _this = this;

      if(this.captchaParams == null){
        this.$refs.refVerify.show();
        return;
      }

      if (valid) {
        let formData = {
          captcha:this.captchaParams,
          account:username,
          password:_this.$codeavatar.help.aesEncrypt(password),
          type:1
        };
        this.$codeavatar.http._post(this.$codeavatar.getApiRealPath(this.$codeavatar.api.unauth_login),formData,(data)=>{
          _this.$codeavatar.storeData.setAuthToken(data.codeavatar);
          _this.$codeavatar.storeData.setSafeApiPath(data.safe_api_path);
          this.$router.push('/admin');
        },null,()=>{
          _this.onChkError();//重置验证
          _this.handleGetCaptcha();
        });
      }
    },
    //手机
    handleSubmit2 (valid, { mobile, captcha }) {
      let _this = this;
      if (valid) {
        this.$codeavatar.http._post(this.$codeavatar.getApiRealPath(this.$codeavatar.api.unauth_login),{mobile,code:captcha,type:2},(data)=>{
          _this.$codeavatar.storeData.setAuthToken(data.codeavatar);
          _this.$codeavatar.storeData.setSafeApiPath(data.safe_api_path);
          this.$router.push('/admin');
        },null,()=>{
          _this.onChkError();//重置验证
        });
      }
    },
    //扫码
    handleSubmit3 (valid, { mobile, captcha }) {
      if (valid) {
        this.$Modal.info({
          title: '输入的内容如下：',
          content: 'mobile: ' + mobile + ' | captcha: ' + captcha
        });

        this.$router.push('/admin');
      }
    },
    //图文
    handleGetCaptcha() {
      // 这里只是模拟切换图形验证码
      this.captchaUrl = this.$codeavatar.config.baseurl + this.$codeavatar.getApiRealPath(this.$codeavatar.api.unauth_captcha)+'?'+Math.random();
    },
    //验证码
    handleGetCode(value, values){
      let _this = this;
      this.$codeavatar.http._post(this.$codeavatar.getApiRealPath(this.$codeavatar.api.unauth_sms),{mobile:values.mobile},()=>{
        _this.$Message.info('已发送验证码');
      });
    },
  },
  created() {
    // this.handleGetCaptcha();
    this.systemName = setting.app.allName;
  },
  mounted() {
    //登录标识
    // if(this.$codeavatar.storeData.isAuthToken()) this.$router.push('/admin');
  }
}
</script>

<style lang="scss" scoped>
.ca-login{
  width: 100%; height: 100%;
  position: relative;
  background-color: rgba(56,93,226,1);
  background-image: url("@/assets/img/basic/login_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .sysName{
    width: 800px; text-align: center; height: 60px; line-height: 60px;
    font-size: 50px; font-weight: bolder; color: #FFFFFF; letter-spacing: 10px;
    word-spacing: 10px; overflow: hidden; text-overflow: ellipsis; display: inline-block;
    position: absolute; left: calc(calc(100vw - 800px) / 2); top: calc(calc(100vh - 600px) / 2);
  }
  .copyright{
    width: 100%; height: 100px; position: absolute; bottom: 0px;
    display: flex; display: -webkit-flex; align-items: center; justify-content: center;
  }
  .tabs{
    width: 450px; height: 340px;
    position: absolute; left: calc(calc(100vw - 450px) / 2); top: calc(calc(100vh - 340px) / 2);
    background-color: rgba(239,239,239,0.8); border-radius: 10px; padding: 20px;
    .ivu-tabs-tabpane{
      padding: 15px 0px 20px 0px;
    }
    .account{
      .title{
        padding-bottom: 5px;
      }
      .submit{
         margin-top: 20px;
      }
    }
    .scanner{
      text-align: center;
      .ca-img{
        width: 200px; height: 200px;
        background-color: #FFFFFF;
      }
    }
    .ca-captcha{
      button{
        background-color: #e6a23c;
      }
      img{
        width: 100%; height: 100%;
      }
    }

  }
}

</style>