/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
import {Message} from "view-ui-plus";
import axiosCore from "@/codeavatar/tool/http/axios-core";
import {HttpCode as httpCode} from "@/codeavatar/tool/http/http-code";
import router from "@/codeavatar/router/router";
import store from "@/codeavatar/data/data";

import logTool from "@/codeavatar/tool/log-tool";
import {libSign} from "@/codeavatar/tool/lib/lib-sign";

export default {
    baseurl(){
        let config = axiosCore.defaults;
        return config.baseURL;
    },

    //响应处理
    response(resData, successCallback, failCallback){
        logTool.debug('[axios-http-*] 》resData',resData);

        if (resData.caCode == httpCode.success) {
            successCallback && successCallback(resData.caData,resData);
        } else if(resData.caCode == httpCode.nologin){
            Message.error({
                background: true,
                content: '登录凭证已过期，请重新登录'
            });
            store.clear();
            router.push({path:'/',replace:true});
        } else {
            if(failCallback){
                failCallback && failCallback(resData);
            }else{
                Message.error({
                    background: true,
                    content: resData.caMsg
                });
            }
        }
    },

    get(url, params, successCallback, failCallback, completeCallback, errorCallback) {
        let _this = this;
        const config = {
            method: 'get',
            url: url
        }
        if (params) config.params = params
        axiosCore(config).then(function (resp) {
            if (200 == resp.status) {
                let resData = JSON.parse(resp.data);
                _this.response(resData,successCallback, failCallback);
            } else {
                logTool.warn('[axios-http-get]',resp);
            }
        })
            .catch(function (err) {
                logTool.error('[axios-http-get]',err);
                errorCallback && errorCallback(err);
            })
            .finally(res => {
                completeCallback && completeCallback(res);
            });
    },
    post(url, params, successCallback, failCallback, completeCallback, errorCallback) {
        let _this = this;
        const config = {
            method: 'post',
            url: url
        }
        if (params) config.data = params;
        return axiosCore(config).then(function (resp) {
            if (200 == resp.status) {
                let resData = JSON.parse(resp.data);
                _this.response(resData,successCallback, failCallback);
            } else {
                logTool.warn('[axios-http-get]',resp);
            }
        })
            .catch(err => {
                logTool.error('[axios-http-post]',err);
                errorCallback && errorCallback(err);
            })
            .finally(res => {
                completeCallback && completeCallback(res);
            });
    },
    delete(url, params, successCallback, failCallback, completeCallback, errorCallback) {
        let _this = this;
        const config = {
            method: 'delete',
            url: url
        }
        if (params) config.data = params
        return axiosCore(config).then(function (resp) {
            if (200 == resp.status) {
                let resData = JSON.parse(resp.data);
                _this.response(resData,successCallback, failCallback);
            } else {
                logTool.warn('[axios-http-get]',resp);
            }
        })
            .catch(function (err) {
                logTool.error('[axios-http-delete]',err);
                errorCallback && errorCallback(err);
            })
            .finally(res => {
                completeCallback && completeCallback(res);
            });
    },
    put(url, params, successCallback, failCallback, completeCallback, errorCallback) {
        let _this = this;
        const config = {
            method: 'put',
            url: url
        }
        if (params) config.data = params
        axiosCore(config).then(function (resp) {
            if (200 == resp.status) {
                let resData = JSON.parse(resp.data);
                _this.response(resData,successCallback, failCallback);
            } else {
                logTool.warn('[axios-http-get]',resp);
            }
        })
            .catch(function (err) {
                logTool.error('[axios-http-put]',err);
                errorCallback && errorCallback(err);
            })
            .finally(res => {
                completeCallback && completeCallback(res);
            });
    },
    _get(url, params, successCallback, failCallback, completeCallback, errorCallback) {
        let _this = this;
        const config = {};
        if (params) {
            libSign.makeSign(params,store.getAuthToken());
            config.params = params;
        }
        return axiosCore.get(url, config)
            .then(function (resp) {
                if (200 == resp.status) {
                    let resData = JSON.parse(resp.data);
                    _this.response(resData,successCallback, failCallback);
                } else {
                    logTool.warn('[axios-http-_get]',resp);
                    errorCallback && errorCallback(resp);
                }
            })
            .catch(function (err) {
                logTool.error('[axios-http-_get]',err);
                errorCallback && errorCallback(err);
            })
            .finally(res => {
                completeCallback && completeCallback(res);
            });
    },
    /**
     *
     * @param url
     * @param params 示例：a=val1&b=val2
     * @param successCallback
     * @param failCallback
     * @param completeCallback
     * @param errorCallback
     * @returns {Promise<unknown>}
     * @private
     */
    _post(url, params, successCallback, failCallback, completeCallback, errorCallback) {
        let _this = this;
        const config = {};
        libSign.makeSign(params,store.getAuthToken());
        return axiosCore.post(url, params, config)
            .then(function (resp) {
                if (200 == resp.status) {
                    let resData = JSON.parse(resp.data);
                    _this.response(resData,successCallback, failCallback);
                } else {
                    logTool.warn('[axios-http-_post]',resp);
                    errorCallback && errorCallback(resp);
                }
            })
            .catch(function (err) {
                logTool.error('[axios-http-_post]',err);
                errorCallback && errorCallback(err);
            })
            .finally(() => {
                completeCallback && completeCallback();
            });
    },
    _delete(url, params, successCallback, failCallback, completeCallback, errorCallback) {
        let _this = this;
        const config = {}
        if (params){
            libSign.makeSign(params,store.getAuthToken());
            config.params = params;
            config.data = params;
        }

        return axiosCore.delete(url, config)
            .then(function (resp) {
                if (200 == resp.status) {
                    let resData = JSON.parse(resp.data);
                    _this.response(resData,successCallback, failCallback);
                } else {
                    logTool.warn('[axios-http-_delete]',resp);
                    errorCallback && errorCallback(resp);
                }
            })
            .catch(function (err) {
                logTool.error('[axios-http-_delete]',err);
                errorCallback && errorCallback(err);
            })
            .finally(res => {
                completeCallback && completeCallback(res);
            });
    },
    _put(url, params, successCallback, failCallback, completeCallback, errorCallback) {
        let _this = this;
        const config = {};
        libSign.makeSign(params,store.getAuthToken());
        return axiosCore.put(url, params, config)
            .then(function (resp) {
                if (200 == resp.status) {
                    let resData = JSON.parse(resp.data);
                    _this.response(resData,successCallback, failCallback);
                } else {
                    logTool.warn('[axios-http-_put]',resp);
                    errorCallback && errorCallback(resp);
                }
            })
            .catch(function (err) {
                logTool.error('[axios-http-_put]',err);
                errorCallback && errorCallback(err);
            })
            .finally(res => {
                completeCallback && completeCallback(res);
            });
    }
}