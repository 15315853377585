/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

import config from "@/codeavatar/config/config";

//日志工具
export default {
    //调试
    // eslint-disable-next-line no-unused-vars
    debug:(tag, optionalParams)=>{
        if(!config.debug) return;

        console.debug('%%%%%%%%%%%%%%%%%%%%【'+tag+' 开始】%%%%%%%%%%%%%%%%%%%%');
        console.debug(optionalParams);
        console.debug('!!!!!!!!!!!!!!!!!!!【'+tag+' 结束】!!!!!!!!!!!!!!!!!!!!');
    },
    //日志
    log:(tag, optionalParams)=>{
        if(!config.debug) return;

        console.log('====================【'+tag+' 开始】====================');
        console.log(optionalParams);
        console.log('!!!!!!!!!!!!!!!!!!!!【'+tag+' 结束】!!!!!!!!!!!!!!!!!!!!');
    },
    //信息
    info:(tag, optionalParams)=>{
        if(!config.debug) return;

        console.info('++++++++++++++++++++【'+tag+' 开始】++++++++++++++++++++');
        console.info(optionalParams);
        console.info('!!!!!!!!!!!!!!!!!!!!【'+tag+' 结束】!!!!!!!!!!!!!!!!!!!!');
    },
    //警告
    warn:(tag, optionalParams)=>{
        if(!config.debug) return;

        console.warn('@@@@@@@@@@@@@@@@@@@@【'+tag+' 开始】@@@@@@@@@@@@@@@@@@@@');
        console.warn(optionalParams);
        console.warn('!!!!!!!!!!!!!!!!!!!!【'+tag+' 结束】!!!!!!!!!!!!!!!!!!!!');
    },
    //错误
    error:(tag, optionalParams)=>{
        if(!config.debug) return;

        console.error('####################【'+tag+' 开始】####################');
        console.error(optionalParams);
        console.error('!!!!!!!!!!!!!!!!!!!!【'+tag+' 结束】!!!!!!!!!!!!!!!!!!!!');
    },
}