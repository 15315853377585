/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

export default {

  //====================[企业]====================


  /**
   * 操作说明书
   */
  enterprise_manual_list:'/enterprise.Manual/list',
  enterprise_manual_save:'/enterprise.Manual/add',
  enterprise_manual_read:'/enterprise.Manual/query',
  enterprise_manual_update:'/enterprise.Manual/edit',
  enterprise_manual_delete:'/enterprise.Manual/remove',
  enterprise_manual_index:'/enterprise.Manual/index',
  enterprise_manual_status:'/enterprise.Manual/status',
  enterprise_manual_top:'/enterprise.Manual/top',
  enterprise_manual_copy:'/enterprise.Manual/copy',
  enterprise_manual_catalog_list:'/enterprise.Manual/catalog_list',
  enterprise_manual_catalog_read:'/enterprise.Manual/catalog_query',
  enterprise_manual_catalog_save:'/enterprise.Manual/catalog_add',
  enterprise_manual_catalog_update:'/enterprise.Manual/catalog_edit',
  enterprise_manual_catalog_delete:'/enterprise.Manual/catalog_remove',
  enterprise_manual_catalog_index:'/enterprise.Manual/catalog_index',

  /**
   * 安全测试
   */
  enterprise_safety_list:'/enterprise.Safety/list',
  enterprise_safety_read:'/enterprise.Safety/query',
  enterprise_safety_delete:'/enterprise.Safety/remove',
  enterprise_safety_index:'/enterprise.Safety/index',

  /**
   * BUG管理
   */
  enterprise_bug_list:'/enterprise.Bug/list',
  enterprise_bug_save:'/enterprise.Bug/add',
  enterprise_bug_read:'/enterprise.Bug/query',
  enterprise_bug_update:'/enterprise.Bug/edit',
  enterprise_bug_delete:'/enterprise.Bug/remove',
  enterprise_bug_index:'/enterprise.Bug/index',
  enterprise_bug_status:'/enterprise.Bug/status',
  enterprise_bug_comment_save:'/enterprise.Bug/comment_add',
  enterprise_bug_comment_update:'/enterprise.Bug/comment_edit',
  enterprise_bug_comment_delete:'/enterprise.Bug/comment_remove',

  /**
   * 源码管理
   */
  enterprise_code_list:'/enterprise.Code/list',
  enterprise_code_save:'/enterprise.Code/add',
  enterprise_code_read:'/enterprise.Code/query',
  enterprise_code_update:'/enterprise.Code/edit',
  enterprise_code_delete:'/enterprise.Code/remove',
  enterprise_code_index:'/enterprise.Code/index',

  /**
   * 接口管理
   */
  enterprise_api_list:'/enterprise.Api/list',
  enterprise_api_save:'/enterprise.Api/add',
  enterprise_api_read:'/enterprise.Api/query',
  enterprise_api_update:'/enterprise.Api/edit',
  enterprise_api_delete:'/enterprise.Api/remove',
  enterprise_api_index:'/enterprise.Api/index',
  enterprise_api_copy:'/enterprise.Api/copy',
  enterprise_api_catalog_list:'/enterprise.Api/catalog_list',
  enterprise_api_catalog_read:'/enterprise.Api/catalog_query',
  enterprise_api_catalog_save:'/enterprise.Api/catalog_add',
  enterprise_api_catalog_update:'/enterprise.Api/catalog_edit',
  enterprise_api_catalog_delete:'/enterprise.Api/catalog_remove',
  enterprise_api_catalog_index:'/enterprise.Api/catalog_index',

  /**
   * 发布说明
   */
  enterprise_release_list:'/enterprise.Release/list',
  enterprise_release_save:'/enterprise.Release/add',
  enterprise_release_read:'/enterprise.Release/query',
  enterprise_release_update:'/enterprise.Release/edit',
  enterprise_release_delete:'/enterprise.Release/remove',
  enterprise_release_index:'/enterprise.Release/index',

  /**
   * 研发进度
   */
  enterprise_process_list:'/enterprise.Process/list',
  enterprise_process_save:'/enterprise.Process/add',
  enterprise_process_read:'/enterprise.Process/query',
  enterprise_process_update:'/enterprise.Process/edit',
  enterprise_process_delete:'/enterprise.Process/remove',
  enterprise_process_index:'/enterprise.Process/index',

  /**
   * 项目预算
   */
  enterprise_quotation_list:'/enterprise.Quotation/list',
  enterprise_quotation_save:'/enterprise.Quotation/add',
  enterprise_quotation_read:'/enterprise.Quotation/query',
  enterprise_quotation_update:'/enterprise.Quotation/edit',
  enterprise_quotation_delete:'/enterprise.Quotation/remove',
  enterprise_quotation_index:'/enterprise.Quotation/index',
  enterprise_quotation_config_list:'/enterprise.Quotation/config_list',
  enterprise_quotation_config_read:'/enterprise.Quotation/config_query',
  enterprise_quotation_config_save:'/enterprise.Quotation/config_add',
  enterprise_quotation_config_update:'/enterprise.Quotation/config_edit',
  enterprise_quotation_config_delete:'/enterprise.Quotation/config_remove',
  enterprise_quotation_config_index:'/enterprise.Quotation/config_index',

  /**
   * 项目信息
   */
  enterprise_project_list:'/enterprise.Project/list',
  enterprise_project_save:'/enterprise.Project/add',
  enterprise_project_read:'/enterprise.Project/query',
  enterprise_project_update:'/enterprise.Project/edit',
  enterprise_project_delete:'/enterprise.Project/remove',
  enterprise_project_copy:'/enterprise.Project/copy',
  enterprise_project_index:'/enterprise.Project/index',
  enterprise_project_top:'/enterprise.Project/top',
  enterprise_project_data_list:'/enterprise.Project/data_list',
  enterprise_project_data_read:'/enterprise.Project/data_query',
  enterprise_project_data_save:'/enterprise.Project/data_add',
  enterprise_project_data_update:'/enterprise.Project/data_edit',
  enterprise_project_data_delete:'/enterprise.Project/data_remove',

  /**
   * 知识文档
   */
  enterprise_document_list:'/enterprise.Document/list',
  enterprise_document_save:'/enterprise.Document/add',
  enterprise_document_read:'/enterprise.Document/query',
  enterprise_document_update:'/enterprise.Document/edit',
  enterprise_document_delete:'/enterprise.Document/remove',
  enterprise_document_status:'/enterprise.Document/status',
  enterprise_document_index:'/enterprise.Document/index',
  enterprise_document_top:'/enterprise.Document/top',
  enterprise_document_chapter_list:'/enterprise.Document/chapter_list',
  enterprise_document_chapter_read:'/enterprise.Document/chapter_query',
  enterprise_document_chapter_save:'/enterprise.Document/chapter_add',
  enterprise_document_chapter_update:'/enterprise.Document/chapter_edit',
  enterprise_document_chapter_delete:'/enterprise.Document/chapter_remove',
  enterprise_document_chapter_index:'/enterprise.Document/chapter_index',

  /**
   * 知识碎片
   */
  enterprise_fragment_list:'/enterprise.Fragment/list',
  enterprise_fragment_save:'/enterprise.Fragment/add',
  enterprise_fragment_read:'/enterprise.Fragment/query',
  enterprise_fragment_update:'/enterprise.Fragment/edit',
  enterprise_fragment_delete:'/enterprise.Fragment/remove',
  enterprise_fragment_status:'/enterprise.Fragment/status',
  enterprise_fragment_index:'/enterprise.Fragment/index',
  enterprise_fragment_top:'/enterprise.Fragment/top',

  /**
   * 开发帐户
   */
  enterprise_account_list:'/enterprise.Account/list',
  enterprise_account_save:'/enterprise.Account/add',
  enterprise_account_read:'/enterprise.Account/query',
  enterprise_account_update:'/enterprise.Account/edit',
  enterprise_account_delete:'/enterprise.Account/remove',
  enterprise_account_status:'/enterprise.Account/status',
  enterprise_account_index:'/enterprise.Account/index',
  enterprise_account_password:'/enterprise.Account/password',
  enterprise_account_newpwd:'/enterprise.Account/newpwd',

  /**
   * 过期提醒
   */
  enterprise_remind_list:'/enterprise.Remind/list',
  enterprise_remind_save:'/enterprise.Remind/add',
  enterprise_remind_read:'/enterprise.Remind/query',
  enterprise_remind_update:'/enterprise.Remind/edit',
  enterprise_remind_delete:'/enterprise.Remind/remove',
  enterprise_remind_status:'/enterprise.Remind/status',
  enterprise_remind_index:'/enterprise.Remind/index',
  enterprise_remind_delay:'/enterprise.Remind/delay',
  enterprise_remind_task:'/enterprise.Remind/task',

  //====================[控制台]====================

  /**
   * 新闻内容
   */
  workbench_news_list:'/workbench.News/list',
  workbench_news_save:'/workbench.News/add',
  workbench_news_read:'/workbench.News/query',
  workbench_news_update:'/workbench.News/edit',
  workbench_news_delete:'/workbench.News/remove',
  workbench_news_status:'/workbench.News/status',
  workbench_news_index:'/workbench.News/index',
  workbench_news_top:'/workbench.News/top',
  /**
   * 单页内容
   */
  workbench_paper_list:'/workbench.Paper/list',
  workbench_paper_read:'/workbench.Paper/query',
  workbench_paper_update:'/workbench.Paper/edit',
  /**
   * 横幅图片
   */
  workbench_banner_list:'/workbench.Banner/list',
  workbench_banner_read:'/workbench.Banner/query',
  workbench_banner_update:'/workbench.Banner/edit',
  /**
   * 数据概况
   */
  workbench_data_list:'/workbench.Data/list',

  //====================[系统监控]====================

  /**
   * 缓存数据
   */
  monitor_cache_list:'/monitor.Cache/list',
  monitor_cache_index:'/monitor.Cache/index',
  monitor_cache_delete:'/monitor.Cache/remove',
  /**
   * 在线用户
   */
  monitor_online_list:'/monitor.Online/list',
  monitor_online_read:'/monitor.Online/query',
  monitor_online_exit:'/monitor.Online/exit',
  /**
   * Redis服务
   */
  monitor_redis_list:'/monitor.Redis/list',
  /**
   * 服务环境
   */
  monitor_server_list:'/monitor.Server/list',
  /**
   * 登录日志
   */
  monitor_login_list:'/monitor.Login/list',
  monitor_login_read:'/monitor.Login/query',
  monitor_login_delete:'/monitor.Login/remove',
  monitor_login_clean:'/monitor.Login/clean',
  monitor_login_export:'/monitor.Login/export',
  monitor_login_police:'/monitor.Login/police',
  /**
   * 操作日志
   */
  monitor_action_list:'/monitor.Action/list',
  monitor_action_read:'/monitor.Action/query',
  monitor_action_delete:'/monitor.Action/remove',
  monitor_action_clean:'/monitor.Action/clean',
  monitor_action_export:'/monitor.Action/export',
  monitor_action_police:'/monitor.Action/police',

  //====================[系统菜单]====================
  /**
   * 数据权限
   */
  system_permission_list:'/system.Permission/list',
  system_permission_status:'/system.Permission/status',
  /**
   * 系统部门
   */
  system_department_list:'/system.Department/list',
  system_department_save:'/system.Department/add',
  system_department_read:'/system.Department/query',
  system_department_update:'/system.Department/edit',
  system_department_delete:'/system.Department/remove',
  system_department_index:'/system.Department/index',
  /**
   * 系统消息
   */
  system_message_list:'/system.Message/list',
  system_message_save:'/system.Message/add',
  system_message_read:'/system.Message/query',
  system_message_update:'/system.Message/edit',
  system_message_delete:'/system.Message/remove',
  system_message_index:'/system.Message/index',
  system_message_status:'/system.Message/status',
  /**
   * 系统公告
   */
  system_notification_list:'/system.Notification/list',
  system_notification_save:'/system.Notification/add',
  system_notification_read:'/system.Notification/query',
  system_notification_update:'/system.Notification/edit',
  system_notification_delete:'/system.Notification/remove',
  system_notification_index:'/system.Notification/index',
  system_notification_status:'/system.Notification/status',
  /**
   * 系统通知
   */
  system_notice_list:'/system.Notice/list',
  system_notice_save:'/system.Notice/add',
  system_notice_read:'/system.Notice/query',
  system_notice_update:'/system.Notice/edit',
  system_notice_delete:'/system.Notice/remove',
  system_notice_index:'/system.Notice/index',
  system_notice_status:'/system.Notice/status',
  /**
   * 安全巡查
   */
  system_police_list:'/system.Police/list',
  system_police_start:'/system.Police/start',
  system_police_save:'/system.Police/add',
  system_police_update:'/system.Police/edit',
  system_police_read:'/system.Police/query',
  system_police_delete:'/system.Police/remove',
  /**
   * 病毒
   */
  system_virus_list:'/system.Virus/list',
  system_virus_delete:'/system.Virus/remove',
  system_virus_scan:'/system.Virus/scan',
  system_virus_clean:'/system.Virus/clean',
  system_virus_read:'/system.Virus/query',
  system_virus_save:'/system.Virus/add',
  system_virus_update:'/system.Virus/edit',
  system_virus_upgrade:'/system.Virus/upgrade',
  system_virus_export:'/system.Virus/export',
  system_virus_download:'/system.Virus/download',
  system_virus_index:'/system.Virus/index',
  /**
   * 数据库
   */
  system_database_list:'/system.Database/list',
  system_database_status:'/system.Database/status',
  system_database_read:'/system.Database/query',
  system_database_delete:'/system.Database/remove',
  system_database_backup:'/system.Database/backup',
  system_database_recovery:'/system.Database/recovery',
  system_database_optimize:'/system.Database/optimize',
  system_database_repair:'/system.Database/repair',
  /**
   * 预警规则
   */
  system_warning_list:'/system.Warning/list',
  system_warning_update:'/system.Warning/edit',
  system_warning_index:'/system.Warning/index',
  /**
   * 联系人
   */
  system_contact_list:'/system.Contact/list',
  system_contact_save:'/system.Contact/add',
  system_contact_read:'/system.Contact/query',
  system_contact_update:'/system.Contact/edit',
  system_contact_delete:'/system.Contact/remove',
  system_contact_index:'/system.Contact/index',
  system_contact_status:'/system.Contact/status',
  /**
   * 数据组
   */
  system_group_list:'/system.Group/list',
  system_group_save:'/system.Group/add',
  system_group_read:'/system.Group/query',
  system_group_update:'/system.Group/edit',
  system_group_delete:'/system.Group/remove',
  system_group_index:'/system.Group/index',
  /**
   * 定时任务
   */
  system_task_list:'/system.Task/list',
  system_task_delete:'/system.Task/remove',
  system_task_clean:'/system.Task/clean',
  system_task_redo:'/system.Task/redo',
  system_task_log:'/system.Task/log',
  system_task_start:'/system.Task/start',
  system_task_stop:'/system.Task/stop',
  system_task_process:'/system.Task/process',
  system_task_state:'/system.Task/state',
  system_task_index:'/system.Task/index',
  /**
   * 系统任务
   */
  system_queue_list:'/system.Queue/list',
  system_queue_delete:'/system.Queue/remove',
  system_queue_clean:'/system.Queue/clean',
  system_queue_redo:'/system.Queue/redo',
  system_queue_log:'/system.Queue/log',
  system_queue_start:'/system.Queue/start',
  system_queue_stop:'/system.Queue/stop',
  system_queue_state:'/system.Queue/state',
  system_queue_index:'/system.Queue/index',
  /**
   * 参数设置
   */
  system_config_list:'/system.Config/list',
  /**
   * 管理员
   */
  system_manager_list:'/system.Administrator/list',
  system_manager_save:'/system.Administrator/add',
  system_manager_read:'/system.Administrator/query',
  system_manager_update:'/system.Administrator/edit',
  system_manager_delete:'/system.Administrator/remove',
  system_manager_status:'/system.Administrator/status',
  system_manager_index:'/system.Administrator/index',
  system_manager_roles:'/system.Administrator/roles',
  system_manager_resetpwd:'/system.Administrator/resetpwd',
  /**
   * 角色
   */
  system_role_list:'/system.Role/list',
  system_role_save:'/system.Role/add',
  system_role_read:'/system.Role/query',
  system_role_update:'/system.Role/edit',
  system_role_delete:'/system.Role/remove',
  system_role_status:'/system.Role/status',
  system_role_index:'/system.Role/index',
  system_role_menus:'/system.Role/menus',
  system_role_departments:'/system.Role/departments',
  /**
   * 菜单
   */
  system_menu_list:'/system.Menu/list',
  system_menu_save:'/system.Menu/add',
  system_menu_read:'/system.Menu/query',
  system_menu_update:'/system.Menu/edit',
  system_menu_delete:'/system.Menu/remove',
  system_menu_index:'/system.Menu/index',

  //====================[其他权限]====================

  /**
   * 个人资料
   */
  other_personal_list:'/other.Personal/list',
  other_personal_update:'/other.Personal/edit',
  /**
   * 安全设置
   */
  other_setting_list:'/other.Setting/list',
  other_setting_resetpwd:'/other.Setting/reset',


  //====================[基础权限]====================

  /**
   * 授权
   */
  auth_safe_api_path:'/codeavatar/basic.Auth/safeApiPath',
  auth_menus:'/basic.Auth/menus',
  auth_info:'/basic.Auth/info',
  auth_logout:'/basic.Auth/logout',
  plug_upload_file:'/basic.Upload/file',
  plug_upload_files:'/basic.Upload/files',
  /**
   * 非授权
   */
  unauth_login:'/codeavatar/basic.Login/index',
  unauth_captcha:'/codeavatar/basic.Robot/code',
  unauth_sms:'/codeavatar/basic.Login/sms',
};