/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
import LoginView from "@/views/LoginView";
import MainView from "@/views/MainView";
import HomeView from "@/views/HomeView";

//error路由
import errorRouter from "@/codeavatar/router/error-router";
//系统路由
import systemRouter from "@/codeavatar/router/system-router";
import monitorRouter from "@/codeavatar/router/monitor-router";
import workbenchRouter from "@/codeavatar/router/workbench-router";
import enterpriseRouter from "@/codeavatar/router/enterprise-router";


export default [
    {
        name: 'admin',
        path:'/admin',
        component:MainView,
        children:[
            {
                path: 'home',
                name:'home',
                component:HomeView,
                meta:{
                    requiresAuth:false,
                    authCode:'system:administrator:list',
                },
            },
            enterpriseRouter,
            workbenchRouter,
            monitorRouter,
            systemRouter,
            errorRouter,
        ],
        meta:{
            requiresAuth:false,
        },
    },
    {
        path:'/',
        name:'login',
        component:LoginView,
        meta:{
            requiresAuth:false,
        },
    },
    {
        path:'/error',
        children: [
            {
                path: '403',
                name: '403',
                component:()=>import('@/views/basic/403'),
                meta:{
                    requiresAuth:false,
                },
            },
            {
                path: '404',
                name: '404',
                component:()=>import('@/views/basic/404'),
                meta:{
                    requiresAuth:false,
                },
            },
            {
                path: '500',
                name: '500',
                component:()=>import('@/views/basic/500'),
                meta:{
                    requiresAuth:false,
                },
            },
        ]
    }
];