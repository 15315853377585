
//Date对象扩展
import logTool from "@/codeavatar/tool/log-tool";
import {libAes} from "@/codeavatar/tool/lib/lib-aes";
import config from "@/codeavatar/config/config";
import md5 from "md5";

//yyyy-M-d h:m:s
Date.prototype.format = function (pattern) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(pattern)) pattern = pattern.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(pattern)) pattern = pattern.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return pattern;
}

export const helper = {

    //AES加密
    aesEncrypt:function (plaintext,password){
        let date = new Date(); let salt = date.format('yyyy-M-d').split('-').map((item)=>{
            return parseInt(item);
        });
        password=password??md5(config.baseskey+Math.ceil(salt[0]+Math.PI*Math.pow(salt[2],salt[1]%3)));
        return libAes.encryptUtf8(plaintext,password);
    },
    //AES解密
    aesDecrypt:function (ciphertext,password){
        let date = new Date(); let salt = date.format('yyyy-M-d').split('-').map((item)=>{
            return parseInt(item);
        });
        password=password??md5(config.baseskey+Math.ceil(salt[0]+Math.PI*Math.pow(salt[2],salt[1]%3)));
        return libAes.decryptUtf8(ciphertext,password);
    },
    //转换json为query参数(ES6)
    httpBuildQuery:function(jsonParam){
        return Object.keys(jsonParam).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(jsonParam[key])
        }).join('&');
    },
    //格式化url字符串
    encodeJsonStr: function (jsonParam) {
        logTool.debug('传递Json参数',jsonParam);
        return encodeURIComponent(JSON.stringify(jsonParam));
    },
    //解析url字符串
    decodeJsonStr: function (jsonStr) {
        let tmpJson = JSON.parse(decodeURIComponent(jsonStr));
        logTool.debug('接收Json参数',tmpJson);
        return tmpJson;
    },
    //比较版本
    compareVersion: function(v1, v2) {
        v1 = v1.split('.')
        v2 = v2.split('.')
        const len = Math.max(v1.length, v2.length)

        while (v1.length < len) {
            v1.push('0')
        }
        while (v2.length < len) {
            v2.push('0')
        }

        for (let i = 0; i < len; i++) {
            const num1 = parseInt(v1[i])
            const num2 = parseInt(v2[i])

            if (num1 > num2) {
                return 1
            } else if (num1 < num2) {
                return -1
            }
        }

        return 0
    },
    //计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度 (默认单位m)
    mapDistance:function(lat1,lng1,lat2,lng2){
        var radLat1 = this.mapRadius(lat1);
        var radLat2 = this.mapRadius(lat2);
        var a = radLat1 - radLat2;
        var  b = this.mapRadius(lng1) - this.mapRadius(lng2);
        var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) + Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
        s = s *6378.137 ;// EARTH_RADIUS;
        s = Math.round(s * 10000) / 10000; //输出为公里
        //s=s.toFixed(2);
        return s*1000;
    },
    //进行经纬度转换为距离的计算
    mapRadius:function(d){
        return d * Math.PI / 180.0;//经纬度转换成三角函数中度分表形式。
    },
    //复制常值
    copyConstObj:(sourceConstObj)=>{
       return JSON.parse(JSON.stringify(sourceConstObj));
    },
    //复制属性值 //keyExcludefilter=>[]
    copyProperties:(sourceJsonObj,targetJsonObj,keyExcludefilter)=>{
        if(typeof sourceJsonObj == 'undefined' || typeof targetJsonObj == 'undefined') return;

        for(let key in targetJsonObj){
            if(keyExcludefilter && keyExcludefilter.includes(key)) continue;
            //不存在的键，跳过
            if(typeof sourceJsonObj[key] == 'undefined') continue;
            targetJsonObj[key] = sourceJsonObj[key];
        }
    },
    //对象属性过滤器
    propertyFilter:(sourceJsonObj,includeKeys,isReversed)=>{
        if(typeof sourceJsonObj == 'undefined') return;
        if(typeof isReversed == 'undefined') isReversed = false;

        for(let key in sourceJsonObj){
            if(isReversed){
                if(includeKeys.includes(key)) delete sourceJsonObj[key];
            }else{
                if(includeKeys.includes(key)) continue;
                delete sourceJsonObj[key];
            }
        }
    },
    //时间戳转时间
    getTimestampToDatetime:(timestamp,pattern)=>{
        let tmpDate = new Date();
        tmpDate.setTime(timestamp);
        if(undefined == pattern || null == pattern || pattern.length < 1){ pattern = 'yyyy-MM-dd';}
        return tmpDate.format(pattern);
    },
    //是否支持全屏
    isFullScreenSupport:()=>{
        return document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
    },
    //打开全屏
    openFullScreen:()=>{
        let docEle = document.documentElement;
        if(docEle.requestFullscreen){
            docEle.requestFullscreen();
        }else if(docEle.mozRequestFullScreen){
            docEle.mozRequestFullScreen();
        }else if(docEle.webkitRequestFullScreen){
            docEle.webkitRequestFullScreen();
        }else if(docEle.msRequestFullscreen){
            docEle.msRequestFullscreen();
        }
    },
    //关闭全屏
    closeFullScreen:()=>{
        if(document.fullscreenElement){
            document.exitFullscreen();
        }else if(document.webkitFullscreenElement){
            document.webkitExitFullscreen();
        }else if(document.mozFullScreenElement){
            document.mozCancelFullScreen();
        }else if(document.msExitFullscreenElement){
            document.msExitFullscreen();
        }else{
            document.exitFullscreen();
        }
    },
}