/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
export default {
    path:'error',
    children: [
        {
            path: '403',
            name: 'err403',
            component:()=>import('@/views/basic/error403'),
            meta:{
                requiresAuth:false,
            },
        },
        {
            path: '404',
            name: 'err404',
            component:()=>import('@/views/basic/error404'),
            meta:{
                requiresAuth:false,
            },
        },
        {
            path: '500',
            name: 'err500',
            component:()=>import('@/views/basic/error500'),
            meta:{
                requiresAuth:false,
            },
        },
    ]
}